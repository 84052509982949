<script>
import Avatar from "@/components/account/avatar";

/**
 * Profile component
 */
export default {
  components: { Avatar },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<template>
  <div class="card shadow-sm flex-fill">
    <div class="bg-primary">
      <div class="row">
        <div class="col-6">
          <div class="text-white p-3">
            <h5 class="text-white">To ja {{ data.firstName }}!</h5>
            <p>Tutaj znajdziesz informacje o mnie.</p>
          </div>
        </div>
        <div class="col-6 align-self-end text-right">
          <img src="@/assets/images/people_work_vector.png" alt class="img-fluid" style="max-height:150px" />
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-sm-6">
          <div class="avatar-xl profile-user-wid mb-4">
            <Avatar :user-data="data" />
          </div>
          <h5 class="font-size-15 text-truncate">{{ data.firstName }} {{ data.lastName }}</h5>
          <p class="text-muted mb-0">{{ data.position }}</p>
        </div>
        <div class="col-sm-5">
          <div class="pt-4">
            <div class="row">
              <div class="col-12 mt-2">
                <h5 class="font-size-15"></h5>
                <p class="text-muted mb-0 font-size-11">Napisz do mnie:</p>
              </div>
            </div>
            <div class="mt-2">
              <a :href="'mailto:' + data.email" class="btn btn-primary waves-effect waves-light btn-sm">
                {{ data.email }}
                <i class="bx bx-mail-send ml-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
